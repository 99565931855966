@import "../../variables.scss";

.svc-tabbed-menu {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  height: calcSize(8);
}

.svc-tabbed-menu-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}