@import "../variables.scss";

.svc-text {
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  color: $foreground;
}

.svc-text--normal {
  font-size: calcSize(2);
  line-height: calcSize(3);
}

.svc-text--small {
  font-size: calcSize(1.5);
  line-height: calcSize(2);
}

.svc-text--bold {
  font-weight: 600;
}
