.sd-boolean {
  display: flex;
  width: max-content;
  position: relative;
  gap: calcSize(0.5);
  padding: calcSize(0.5);
  background-color: $editor-background;
  border-radius: calcSize(12.5);
  box-shadow:
    $shadow-inner,
    0 0 0 0px $primary;
  transition: box-shadow $transition-duration;

  &.sd-boolean--allowhover:focus-within {
    box-shadow:
      $shadow-inner-reset,
      0 0 0 2px $primary;
  }
}

.sd-boolean__thumb,
.sd-boolean__label {
  @include useEditorFontSize;
  display: block;
  font-family: $font-editorfont-family;
  font-weight: $font-editorfont-weight;
  font-size: $font-editorfont-size;
  color: $font-editorfont-placeholdercolor;
  line-height: multiply(1.5, $font-editorfont-size);
  padding: calcSize(1) calcSize(3);
}

.sd-boolean__switch {
  display: flex;
  padding: calcSize(0.5);
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100%);
  height: calc(100%);
  box-sizing: border-box;
  border-radius: calcSize(12.5);
  overflow: hidden;
}

.sd-boolean.sd-boolean--checked .sd-boolean__label--true,
.sd-boolean:not(.sd-boolean--checked):not(sd-boolean--indeterminate) .sd-boolean__label--false {
  color: transparent;
  transition-duration: 0.15s;
  transition-property: color;
  transition-timing-function: linear;
}

.sd-boolean.sd-boolean--indeterminate .sd-boolean__switch {
  display: none;
}

.sd-boolean--indeterminate .sd-boolean__thumb {
  display: none;
}

.sd-boolean__thumb {
  position: absolute;
  left: calcSize(0.5);
  transform: translateX(0);
  background-color: $question-background;
  box-shadow: $shadow-small;
  border-radius: calcSize(12.5);
  transition-duration: 0.2s;
  transition-property: transform, left;
  transition-timing-function: linear;
  color: $primary;
  font-weight: 600;
  z-index: 2;

  .sv-string-viewer {
    &.sv-string-viewer--multiline {
      white-space: nowrap;
    }
  }
}

.sd-boolean--checked:not(.sd-boolean--exchanged) .sd-boolean__thumb,
.sd-boolean--exchanged:not(.sd-boolean--checked) .sd-boolean__thumb {
  left: calc(100% - calc(0.5 * #{$base-unit}));
  transform: translateX(-100%);
}

.sd-boolean--exchanged {
  &.sd-boolean:not(.sd-boolean--checked):not(sd-boolean--indeterminate) .sd-boolean__label--false,
  &.sd-boolean.sd-boolean--checked .sd-boolean__label--true {
    color: $font-editorfont-placeholdercolor;
  }
}

.sd-boolean--disabled {
  pointer-events: none;

  .sd-boolean__thumb,
  .sd-boolean__label {
    color: $foreground;
    opacity: 0.25;
  }
}

.sd-boolean--readonly {
  pointer-events: none;
  box-shadow: none;
  transition: none;

  background: $background-dark;

  .sd-boolean__thumb {
    box-shadow: inset 0px 0px 0px 2px $foreground;
    transition: none;
    color: $foreground;
  }
}

.sd-boolean--preview {
  pointer-events: none;
  box-shadow: none;
  transition: none;
  background: transparent;

  .sd-boolean__thumb {
    border: 1px solid $foreground;
    box-shadow: none;
    transition: none;
    color: $foreground;
  }

  &.sd-boolean--checked,
  &.sd-boolean--indeterminate {
    .sd-boolean__thumb {
      margin-left: auto;
    }
  }

  .sd-checkbox__label--preview {
    color: $foreground;
  }
}

.sd-boolean__thumb-ghost {
  z-index: 1;
  border-radius: calcSize(12.5);
  background-color: transparent;
  transition: background-color $transition-duration;
}

.sd-boolean.sd-boolean--allowhover .sd-boolean__thumb-ghost:hover {
  background-color: $background-dim-dark;
}

.sd-boolean--error {
  background-color: $red-light;
}
