.spg-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.spg-question__header {
  width: 100%;
}

@mixin question_tile {
  width: 100%;
  margin: 0;
  padding: calcSize(1) 0;
  line-height: calcSize(3);
  font-size: calcSize(2);
  font-family: $font-family;
  color: var(--ctr-label-text-color, $foreground-light);
  font-weight: normal;
  box-sizing: border-box;
}

.spg-question__title {
  @include question_tile;
}

.spg-question__content {
  width: 100%;
  color: $primary;
  cursor: pointer;
}

.spg-text__content,
.spg-comment__content {
  position: relative;
}

.spg-question__content:focus-within .spg-remaining-character-counter {
  display: flex;
}

.spg-remaining-character-counter {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px;
  font-family: $font-family;
  line-height: calcSize(2);
  font-size: calcSize(1.5);
  color: $foreground-light;
  position: absolute;
  inset-inline-end: calcSize(0.75);
  inset-block-end: calcSize(0.5);
}

.spg-question__description {
  line-height: calcSize(2);
  font-size: calcSize(1.5);
  color: $foreground-dim-light;
  padding-bottom: calcSize(2);
  white-space: normal;
}

.spg-row-narrow__question {
  margin-top: calcSize(-2);
}

.spg-row--multiple {
  & > div > .spg-row-narrow__question {
    margin-top: calcSize(-1);
  }
}

.spg-question--location--left {
  flex-direction: row;
  background: $background;
  box-shadow: inset 0 0 0 1px $border-inside;
  line-height: calcSize(6);
  vertical-align: middle;
  align-items: stretch;

  &:focus-within {
    box-shadow: inset 0 0 0 2px $primary;
  }
}

.spg-question__header--location--left {
  width: auto;
  box-sizing: border-box;
  max-width: 50%;
  flex: 1;
  display: flex;
  align-items: center;

  .spg-question__title {
    padding: calcSize(1) calcSize(2);
    border-right: 1px solid $border;
    display: inline-block;
    color: var(--ctr-editor-label-color, $foreground-dim-light);
  }
}

.spg-question__content--left {
  flex: 2;

  .spg-input.spg-input.spg-input {
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:focus,
    &:focus-within {
      box-shadow: none;
    }
  }
}

.spg-row--multiple {
  display: flex;
  gap: calcSize(1);
  flex-wrap: wrap;

  & > div {
    flex-grow: 1;
    min-width: calcSize(25.5);

    .spg-question__header--location--left {
      min-width: calcSize(8);
    }

    input {
      min-width: calc(max(17 * #{$base-unit}, 100%));
      width: 0;
    }
  }
}

.spg-question__erbox {
  padding: calcSize(1) calcSize(1.5) calcSize(1) calcSize(1.5);
  color: var(--ctr-error-message-text-color, $foreground);
  background-color: var(--ctr-error-message-background-color, $red-light);
  border-radius: calcSize(0.5);
  line-height: calcSize(3);
}

.spg-question__erbox,
.spg-question__erbox>svc-question-error,
sv-question-error {
  & > div {
    display: flex;
    gap: calcSize(1);
  }
}



.spg-question__erbox-icon {
  .sv-svg-icon {
    vertical-align: top;
  }

  use {
    fill: var(--ctr-error-message-icon-color, $red);
  }
}

.spg-question__erbox--location--bottom {
  margin-top: calcSize(1);
}

.spg-restfull,
.spg-masksettings {
  .spg-panel__content {
    gap: calcSize(2);

    .spg-row {
      margin-top: 0;
    }
  }
}
