.spg-root-modern {
  .sv-button-group {
    border: 1px solid var(--ctr-button-group-item-border-color, $border);
    transition: box-shadow $creator-transition-duration, border-color $creator-transition-duration, border $creator-transition-duration;
  }

  .sv-button-group:focus-within {
    box-shadow: 0 0 0 1px var(--ctr-button-group-border-color-focused, $primary);
    border-color: var(--ctr-button-group-border-color-focused, $primary);
  }

  .sv-button-group__item {
    background: var(--ctr-button-group-item-background-color, $background);
    transition: background $creator-transition-duration, background-color $creator-transition-duration, color $creator-transition-duration;

    &:not(:last-of-type) {
      border-right: 1px solid var(--ctr-button-group-item-border-color, $border);
    }
  }

  .sv-button-group__item--hover:hover {
    background-color: var(--ctr-button-group-item-background-color-hovered, $background-dim);
  }

  .sv-button-group__item-icon {
    use {
      fill: var(--ctr-button-group-item-icon-color, $foreground-light);
    }
  }

  .sv-button-group__item--selected {
    color: var(--ctr-button-group-item-text-color-selected, $primary);

    .sv-button-group__item-icon use {
      fill: var(--ctr-button-group-item-icon-color-selected, $primary);
    }

    &:hover {
      background-color: var(--ctr-button-group-item-background-color-hovered, $background);
    }
  }

  .sv-button-group__item--disabled {
    color: var(--ctr-button-group-item-text-color-disabled, $foreground);

    .sv-button-group__item-icon use {
      fill: var(--ctr-button-group-item-text-color-disabled, $foreground);
    }

    &:hover {
      background-color: var(--ctr-button-group-item-background-color-disabled, $background);
    }
  }

  .sv-button-group:focus-within {
    box-shadow: 0 0 0 1px var(--ctr-button-group-border-color-focused, $primary);
    border-color: var(--ctr-button-group-border-color-focused, $primary);
  }
}

.spg-root-modern.spg-root--one-category {
  background-color: var(--ctr-property-grid-form-background-color, $background-dim);
  border-right: unset;

  .spg-page.spg-body__page {
    box-shadow: unset;
  }
}
