.toolbox {
  margin: 15px;
  margin-top: 15px;
  background-color: white;
  border-radius: 5px;
}

.toolbox-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 0rem 2rem;
  font-size: 14px;
  margin-top: 30px;
}

@media only screen and (max-width: 1499px) {
  .toolbox-title {
    display: none;
  }
}
