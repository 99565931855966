.spg-link {
  display: flex;
}

.sv-string-viewer {
  .spg-link {
    display: initial;
    color: $primary;
    font-weight: 600;
    text-decoration-line: underline;
  }
}