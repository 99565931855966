.svc-action-button {
  display: inline-block;

  padding: calcSize(0.5) calcSize(2);
  box-sizing: border-box;
  border-radius: calcSize(12.5);

  color: var(--ctr-survey-action-button-text-color-positive, $primary);

  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);

  border: calcSize(0.25) solid transparent;

  .svc-text {
    color: var(--ctr-survey-action-button-text-color-positive, $primary);
  }

  &:focus,
  &:hover {
    background-color: var(--ctr-survey-action-button-background-color-hovered-positive, $primary-light);
    outline: none;
  }
}

.svc-action-button--selected {
  border-color: var(--ctr-survey-action-button-text-color-positive, $primary);
}

.svc-action-button--disabled {
  opacity: var(--ctr-survey-action-button-opacity-disabled, 0.25);
  color: var(--ctr-survey-action-button-text-color-disabled, $foreground);
}

.svc-action-button--pressed {}