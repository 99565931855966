@import "../../variables.scss";
@import "../../variables-layer.scss";

.svc-toolbox__item {
  display: flex;
  position: relative;
  align-items: center;
  z-index: 20;
  outline: none;
  padding-top: var(--ctr-toolbox-item-padding-top, calcSize(0));
  padding-right: var(--ctr-toolbox-item-padding-right, calcSize(1.5));
  padding-bottom: var(--ctr-toolbox-item-padding-bottom, calcSize(0));
  padding-left: var(--ctr-toolbox-item-padding-left, calcSize(1.5));

  sv-svg-icon,
  .sv-svg-icon {
    display: block;
    height: var(--ctr-toolbox-item-icon-height, calcSize(3));
    width: var(--ctr-toolbox-item-icon-width, unset);
  }
}

.svc-toolbox__tool {

  sv-svg-icon,
  .sv-svg-icon {
    pointer-events: none;
  }
}

// is always visible
.svc-toolbox__item-container {
  outline: none;
  padding: calcSize(1) 0;

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color, $foreground-light);
  }
}

//is visible only on hover
.svc-toolbox__item-banner {
  opacity: 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-right: 0;
  margin-left: calcSize(-4.5);
  height: calcSize(5);

  .svc-toolbox__item-icon {
    position: absolute;
    display: block;
    left: calcSize(1.5);
    top: calcSize(1);
  }

  .svc-toolbox__item-title {
    color: var(--ctr-toolbox-item-text-color-hovered, $foreground);
  }

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
  }
}

.svc-creator__toolbox--right,
[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {

  .svc-toolbox__tool:hover,
  .svc-toolbox__tool--hovered,
  .svc-toolbox__item:focus {
    .svc-toolbox__item-banner {
      padding-right: calcSize(1.5);
      padding-left: calcSize(2);
      margin-left: calcSize(-2);
      margin-right: calcSize(-3);
      flex-direction: row-reverse;
      transform: translateX(calc(1.5 * #{$base-unit}));

      .svc-toolbox__item-title {
        padding-right: calcSize(4);
        padding-left: calcSize(1);
      }
    }
  }

  .svc-toolbox__item-banner {
    margin-right: calcSize(-4.5);
    margin-left: 0;
  }

  .svc-toolbox__item {
    flex-direction: row-reverse;
  }

  .svc-toolbox__tool.sv-dots {
    place-self: flex-end;
  }

  .svc-toolbox__category {
    align-items: flex-end;
  }

  .svc-toolbox__tool {
    align-items: flex-end;
  }

  .svc-toolbox__item-banner {
    .svc-toolbox__item-icon {
      left: auto;
      right: calcSize(1.5);
    }
  }
}

.svc-toolbox__item-title {
  @include smallBold;

  display: block;
  padding-left: calcSize(1);
  padding-right: calcSize(1.5);
  white-space: nowrap;
  color: var(--ctr-toolbox-item-text-color, $foreground-light);
}

.svc-toolbox__tool:hover,
.svc-toolbox__tool--hovered,
.svc-toolbox__item:focus {
  .svc-toolbox__item-banner {
    max-width: calcSize(100);
    padding-left: var(--ctr-toolbox-item-padding-left, calcSize(1.5));
    padding-right: var(--ctr-toolbox-item-padding-right, calcSize(2));
    margin-right: calcSize(-2);
    opacity: 1;

    .svc-toolbox__item-title {
      padding-left: calcSize(4);
    }
  }
}

.svc-toolbox__tool--disabled {
  pointer-events: none;

  .sv-svg-icon {
    opacity: var(--ctr-toolbox-item-icon-opacity-disabled, 0.15);

    use {
      fill: var(--ctr-toolbox-item-icon-color-disabled, rgba(0, 0, 0, 0.91));
    }
  }

  .svc-toolbox__item-title {
    opacity: var(--ctr-toolbox-item-text-opacity-disabled, 0.25);
  }
}

.svc-toolbox:not(.svc-toolbox--compact) {

  .svc-toolbox__item:not(.sv-dots):focus,
  .svc-toolbox__tool--hovered>.sv-action__content>.svc-toolbox__item:not(.sv-dots),
  .svc-toolbox__item:not(.sv-dots):hover {
    overflow: hidden;
    border-radius: var(--ctr-toolbox-item-corner-radius, calcSize(12.5));
    background-color: var(--ctr-toolbox-item-background-color-hovered, $background);
    box-shadow: $shadow-medium;
    transition: 0.1s ease-in-out;

    .svc-toolbox__item-title {
      color: var(--ctr-toolbox-item-text-color-hovered, $foreground);
    }

    .sv-svg-icon use {
      fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
    }
  }
}

.svc-toolbox__tool .sv-dots__item:hover:enabled,
.svc-toolbox__tool .sv-dots__item:focus:enabled,
.svc-toolbox__tool .sv-dots__item.sv-action-bar-item--pressed {
  background-color: var(--ctr-toolbox-button-background-color-hovered, $primary-light);
  border-radius: var(--ctr-toolbox-item-corner-radius, calcSize(12.5));

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
  }
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .svc-toolbox-popup {
    direction: rtl;
  }
}

.svc-toolbox-popup {
  .sv-list__item {
    &:first-child {
      .sv-list__item-separator {
        display: none;
      }
    }

    .sv-list__item-body {
      padding-top: calcSize(1.5);
      padding-bottom: calcSize(1.5);
      padding-inline-start: calcSize(2);
      padding-inline-end: calcSize(5);
    }
  }
}

.svc-toolbox,
.svc-toolbox:not(.svc-toolbox--compact) {
  .svc-toolbox__tool--pressed {
    .svc-toolbox__item:not(.sv-dots) {
      color: var(--ctr-toolbox-item-text-color-hovered, $foreground);
      opacity: 0.5;

      .sv-svg-icon use {
        fill: var(--ctr-toolbox-item-icon-color-pressed, $foreground);
        opacity: 0.5;
      }
    }

    .svc-toolbox__item.svc-toolbox__item-subtype {
      opacity: unset;
    }
  }
}

.svc-toolbox-subtypes {
  .sv-popup__container {
    box-shadow: unset;
    background: unset;
  }

  .sv-list__container {
    flex-wrap: wrap;
    column-gap: calcSize(2);
  }

  .sv-popup__shadow {
    box-shadow: unset;
  }

  .sv-popup__body-content {
    background: unset;
    padding: 0;
  }

  .sv-popup__scrolling-content {
    padding: calcSize(1) calcSize(2);
    overflow: visible;
  }

  .sv-list__item:hover>.sv-list__item-body,
  .sv-list__item:focus>.sv-list__item-body {
    background-color: unset;
  }

  .svc-toolbox__item.svc-toolbox__item-subtype {
    opacity: var(--ctr-toolbox-item-opacity-submenu, 0.85);
    overflow: hidden;
    border-radius: calcSize(12.5);
    background: var(--ctr-toolbox-item-background-color-submenu, $layer-2-background-500);
    box-shadow: $shadow-small, $shadow-medium;
    transition: 0.1s ease-in-out;
    padding: calcSize(1.5) calcSize(2);

    .svc-toolbox__item-title {
      color: var(--ctr-toolbox-item-text-color-submenu, $layer-2-foreground-100);
    }
  }

  .svc-toolbox__tool:hover,
  .svc-toolbox__tool--hovered,
  .svc-toolbox__item:focus {
    .svc-toolbox__item-subtype {
      background: var(--ctr-toolbox-item-background-color-hovered, $layer-1-background-500);
      opacity: unset;

      .svc-toolbox__item-title {
        color: var(--ctr-toolbox-item-text-color-hovered, $layer-1-foreground-100);
      }
    }
  }

  .svc-toolbox__item-title {
    padding: 0;
  }
}