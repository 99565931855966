.grid-wrapper-root {
  width: 100%;
  display: flex;
  padding: 5px;
  justify-content: center;
}

/* .save-button {
  width: 85%;
  min-height: 50px;
  color: white;
  background-color: #113483;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
} */
