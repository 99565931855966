@import "../../variables.scss";

.svc-property-panel__group {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: calcSize(2);
  border: none;
  outline: none;
  color: $foreground-light;
  cursor: pointer;
  text-align: left;
  background: $background;
  box-shadow: inset 0px -1px 0px $border;
  font-family: $font-family;
  font-size: calcSize(2);
}

.svc-property-panel__group:disabled,
.svc-property-panel__group:disabled:hover {
  background-color: $background;
  color: $foreground;
  opacity: 0.25;
  cursor: default;
}

.svc-property-panel__group:hover {
  background-color: $background-dim;
  color: $foreground-light;
  font-weight: 400;
}

.svc-property-panel__group--active,
.svc-property-panel__group--active:hover {
  color: $foreground;
  font-weight: 600;
  background-color: $background;
}

.svc-property-grid-placeholder {
  display: flex;
  padding: var(--ctr-property-grid-placeholder-padding-top, 96px)
    var(--ctr-property-grid-placeholder-padding-right, 48px) var(--ctr-property-grid-placeholder-padding-bottom, 0px)
    var(--ctr-property-grid-placeholder-padding-left, 48px);
  flex-direction: column;
  align-items: center;
  gap: var(--ctr-property-grid-placeholder-image-margin-top, 24px);
  flex: 1 0 0;
  align-self: stretch;
  background: var(--ctr-property-grid-form-background-color, #f3f3f3);
}
.svc-property-grid-placeholder__header {
  display: flex;
  max-width: 256px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--ctr-property-grid-placeholder-text-gap, 8px);
  width: 100%;
}
.svc-property-grid-placeholder__title {
  color: var(--ctr-property-grid-placeholder-text-title-color, rgba(0, 0, 0, 0.91));
  text-align: center;
  font-family: var(--ctr-font-family, "Open Sans");
  font-size: var(--ctr-font-default-size, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--ctr-font-default-line-height, 24px) /* 150% */;
  display: inline-block;
}
.svc-property-grid-placeholder__description {
  color: var(--ctr-property-grid-placeholder-text-description-color, rgba(0, 0, 0, 0.45));
  text-align: center;
  font-family: var(--ctr-font-family, "Open Sans");
  font-size: var(--ctr-font-small-size, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ctr-font-small-line-height, 16px) /* 133.333% */;
  display: inline-block;
}
.svc-property-grid-placeholder__content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.svc-property-grid-placeholder__gap {
  max-width: 192px;
  flex: 1 0 0;
  width: 100%;
}
.svc-property-grid-placeholder__image {
  display: flex;
  max-width: 192px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background: url("../../images/image-panel-placeholder.png") 50% / contain no-repeat;
  width: 100%;
  overflow: hidden;
  height: 86px;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"],
.svc-creator__side-bar--left {
  .svc-property-grid-placeholder__image {
    transform: rotateY(180deg);
  }
}

.svc-creator__side-bar--left .svc-property-grid-placeholder__content {
  flex-direction: row-reverse;
}
