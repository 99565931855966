.App {
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.calls {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calls button {
  margin: 0.25rem 0rem;
}

.data {
  flex: 1;
  padding: 1rem;
  text-align: justify;
}
