@import "../variables.scss";

.svd-test-results {
  font-family: $font-family;
  border-radius: 4px;
  box-shadow: inset 0px 1px 0px 0px var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16)));
  padding: calc(12 * var(--sjs-base-unit, var(--base-unit, 8px)));

  .svd-test-results__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calcSize(2) calcSize(3);
    font-size: calcSize(2);
    color: $foreground-light;
    border-bottom: 2px solid $border-light;
    background-color: $background;

    .svd-test-results__header-text {
      margin: 0;
    }

    .svd-test-results__header-types {
      display: flex;
      gap: calcSize(1);
    }
  }

  .svd-test-results__text {
    display: flex;
    padding: calcSize(2) calcSize(3);
    width: calc(100% - calcSize(6));
    overflow: auto;
    user-select: text;
    white-space: pre-wrap;
    background-color: $background;
  }

  .svd-test-results__table {
    background-color: $background;
    width: 100%;
    box-shadow: $shadow-small;

    table {
      width: 100%;
      border-collapse: collapse;

      .svd-test-results__marker {
        position: absolute;
        top: calcSize(1.5);

        .sv-svg-icon {
          transition: transform 0.2s ease-in-out;

          use {
            fill: $foreground-light;
          }
        }

        &.svd-test-results__marker--expanded .sv-svg-icon {
          transform-origin: center;
          transform: rotate(90deg);
        }
      }

      th {
        padding: calcSize(1) calcSize(1);
        background-color: $background-dim;
        text-align: left;
        font-weight: 600;
        font-size: 12px;
        color: $foreground-light;

        &:nth-child(1) {
          padding-left: calcSize(3);
        }
      }

      td {
        padding: calcSize(1) calcSize(1);
        max-width: calcSize(50);
        overflow: auto;
        border-top: 2px solid $border-light;
        user-select: text;

        &:nth-child(1) {
          position: relative;
          width: 50%;
          font-weight: 600;
        }

        &:nth-child(2) {
          width: 50%;
          padding-right: calcSize(3);
        }
      }

      .survey-result-value {
        word-break: break-all;
      }

      tr {
        vertical-align: top;

        .svd-test-results__node-value {
          color: $foreground-light;
        }
      }
    }
  }
}