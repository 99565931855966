@import "../../variables.scss";

svc-tab-test {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-test-tab__content .svc-plugin-tab__content {
  overflow-y: overlay;

  .sv-root-modern .sv-completedpage,
  .sv_default_css .sv_completed_page {
    margin: 0;
    border: 0;
    background-color: $background-dim;
  }

  .sv_default_css .sv_body {
    border: 0;
  }

  .svc-preview__test-again {
    width: calcSize(33);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: calcSize(12);
    margin-top: 8px;
  }
}

.svc-creator-tab__content--with-toolbar.svc-test-tab__content .svc-plugin-tab__content {
  height: calc(100% - calc(6 * #{$base-unit}));
}

.svc-test-tab__content-actions {
  position: relative;

  .sv-action-bar {
    padding: 0;
    height: calc(6 * #{$base-unit});
    border-top: 1px solid var(--ctr-preview-pager-border-color, $border);
    background: var(--ctr-preview-pager-background-color, $background);
    width: 100%;
    position: absolute;
  }

  .sv-action-bar.sv-action-bar--pages {
    left: 0;
    right: 0;
    justify-content: center;
    gap: calcSize(1);

    .sv-action__content {
      padding: 0;
    }

    .sv-action-bar-item {
      margin: 0;
    }
  }

  .sv-action-bar-item {
    @include smallBold;
    width: 100%;
    height: calcSize(4);
    transition: background-color $creator-transition-duration;
  }

  .svc-page-selector {
    max-width: 50%;

    .sv-action-bar-item__title {
      @include textEllipsis;

      color: $foreground;
      display: inline-block;
    }
  }
}

.svc-page-invisible .sv-list-item__marker-icon {
  --sjs-general-forecolor-light: rgba(0, 0, 0, 0.45);

  padding: 0;
  opacity: 0.5;
  margin-inline-end: calcSize(0.5);
}

.svc-test-tab__content .sd-body--empty {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: calcSize(2);
  color: $foreground;
  text-align: center;
  overflow: hidden;
  position: static;
  display: flex;
  flex-direction: column;
}