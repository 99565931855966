@import "../variables.scss";

.svc-flex-container {
  display: flex;
}

.svc-flex-row {
  display: flex;
  flex-direction: row;
  background: var(--ctr-surface-background-color, $background-dim);
}

.svc-full-container {
  height: 100%;
  width: 100%;
}

.svc-flex-row.svc-full-container {
  height: calcSize(60);
  min-height: calcSize(60);
  flex-grow: 1;
  position: relative;
}

.svc-flex-row__element--growing {
  flex: 1;
  overflow: auto;
}

.svc-flex-column {
  display: flex;
  flex-direction: column;
}

.sv-action-bar--default-size-mode .sv-action-bar-separator {
  height: calcSize(5);
}

.sv-action-bar--small-size-mode .sv-action-bar-separator {
  height: calcSize(4);
}

.svc-creator--mobile {
  .sv-action-bar-separator {
    height: calcSize(3);
  }
}

.sv-action-bar--small-size-mode .sv-action-bar-item {
  font-weight: 600;
  align-items: center;
}

.svc-top-bar {
  display: flex;
  background: var(--ctr-menu-item-background-color, $background);
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px var(--ctr-menu-border-color, $border);

  .svc-toolbar-wrapper {
    flex: 0 0 auto;
    display: flex;

    .sv-action-bar {
      padding: 0 calcSize(1);
      justify-content: flex-end;
    }

    .sv-action-bar-separator {
      display: none;
    }

    .sv-action-bar>.sv-action:not(.sv-action--hidden)~.sv-action .sv-action-bar-separator {
      display: inline-block;
    }

    .sv-action .sv-action-bar-item {
      margin: 0 calcSize(1);
    }
  }

  .sv-action-bar-separator {
    background-color: var(--ctr-separator-color, $border);
  }

  .sv-action-bar-item {
    border-radius: var(--ctr-menu-toolbar-button-corner-radius, 2px);
    background-color: transparent;
    color: var(--ctr-menu-toolbar-button-text-color, $foreground);
    transition: background-color $creator-transition-duration;
  }


  .sv-action-bar-item__icon {
    use {
      fill: var(--ctr-menu-toolbar-button-icon-color, $foreground-light);
    }
  }

  //hovered state
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled,
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):focus:enabled {
    background-color: var(--ctr-menu-toolbar-button-background-color-hovered, $background-dim);
  }

  //pressed state
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):active:enabled {
    opacity: var(--ctr-menu-toolbar-button-opacity-pressed, 0.5);
  }

  //disabled state
  .sv-action-bar-item:disabled {
    opacity: var(--ctr-menu-toolbar-button-opacity-disabled, 0.25);
  }

  .sv-action-bar-item--secondary {
    .sv-action-bar-item__icon use {
      fill: $secondary;
    }
  }

  .sv-action-bar-item--active {
    .sv-action-bar-item__icon use {
      fill: var(--ctr-menu-toolbar-button-text-color-selected, $primary);
    }
  }

  .sv-action-bar-item-dropdown {
    border-radius: calcCornerRadius(0.5);
    background-color: transparent;
  }

  .sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
    background-color: var(--ctr-menu-toolbar-button-background-color-pressed, $background-dim);
    opacity: var(--ctr-menu-toolbar-button-opacity-pressed, 50%);
  }
}

.svc-footer-bar {
  .svc-toolbar-wrapper {
    height: calcSize(6);

    .sv-action-bar {
      justify-content: center;
      padding: 0;
      width: 100%;

      height: calc(6 * #{$base-unit});
      background: $background;
      border-top: 1px solid $border;
      box-sizing: border-box;
    }
  }
}